import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeroHeaderImage from "../components/HeroHeaderImage"

const SitePage = () => {
  const {
    siteReformulacao,
    siteManutencao,
    siteOtimizacao,
  } = useStaticQuery(graphql`
    query {
      siteReformulacao: file(
        relativePath: { eq: "layout/site/servico-reformulacao.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siteManutencao: file(
        relativePath: { eq: "layout/site/servico-manutencao.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siteOtimizacao: file(
        relativePath: { eq: "layout/site/servico-otimizacao.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Hospedagem" />
      <HeroHeaderImage
        heroMode={"is-dark"}
        heroClass={"website"}
        heroSize={"is-small"}
        navActive={"website"}
      >
        <div className="container">
          <div className="block is-titulo-hero">
            <div className="columns">
              <div className="column is-6">
                <h1 className="title is-3 is-uppercase">Sua página na web</h1>
                <p>
                  Desenvolvemos seu site <strong>sob medida</strong> e de acordo
                  com suas necessidades. Você vai contar com uma equipe
                  especializada com conhecimento em todas as etapas do processo
                  de <strong>Criação de Sites</strong>, desde o registro do
                  domínio, o estudo do seu projeto, o desenvolvimento das
                  páginas e indo até as formas de promover seu novo site.
                </p>
                <p className="m-t-md">
                  Nosso objetivo é unir um visual agradável com aplicações
                  rápidas e confiáveis, tornando o seu site uma ótima ferramenta
                  de relacionamento e divulgação de sua empresa ou marca na
                  internet.
                </p>
                <p className="m-t-md">
                  Entre em contato com a gente, faça um orçamento sem
                  compromisso!
                </p>
                <Link
                  to="/contato"
                  className="button is-medium is-light is-outlined m-t-md"
                >
                  Fazer um orçamento
                </Link>
              </div>
            </div>
          </div>
        </div>
      </HeroHeaderImage>
      <section className="container m-b-lg">
        <div className="block">
          <h1 className="title is-3 has-text-centered p-lg">Outros Serviços</h1>
          <div className="columns">
            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img
                      fluid={siteReformulacao.childImageSharp.fluid}
                      alt="Reformulação de Sites"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Reformulação de Sites</p>
                  <p>
                    As tecnologias na web estão em mudanças constantes e a
                    reformulação de um site é essencial para sua empresa ou
                    marca terem a visibilidade ideal na internet.
                  </p>
                  <p className="m-t-md">
                    A <strong>SH2</strong> atualiza sua página com as novas
                    tendências, visando um tempo de vida maior do projeto e
                    principalmente tornando seu site <strong>responsivo</strong>
                    .<Link to="/contato">Quero reformular meu site.</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img
                      fluid={siteManutencao.childImageSharp.fluid}
                      alt="Manutenção de Sites"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Manutenção de Sites</p>
                  <p>
                    A manutenção de um site é tão importante quanto sua criação.
                    Manter a estrutura e as informações atualizadas dá
                    credibilidade a sua página.
                  </p>
                  <p className="m-t-md">
                    A <strong>SH2</strong> oferece serviços de atualização de
                    segurança, atualização da plataforma, inserção ou alteração
                    de informações contidas no site e muito mais.
                    <Link to="/contato">Quero manter meu site atualizado.</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-image">
                  <figure className="image">
                    <Img
                      fluid={siteOtimizacao.childImageSharp.fluid}
                      alt="Otimização de Sites e SEO"
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Otimização de Sites e SEO</p>
                  <p>
                    A <strong>SH2</strong> oferece análise e soluções para
                    gargalos de lentidão em seu site, um dos principais motivos
                    que afastam visitantes.
                  </p>
                  <p className="m-t-md">
                    Também oferecemos otimização de buscas (SEO), usando um
                    conjunto de técnicas responsáveis pela melhoria do
                    posicionamento de páginas nos resultados de buscas no
                    Google, Bing, Yahoo, etc.{" "}
                    <Link to="/contato">
                      Quero que meu site seja rápido e encontrado.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-small is-dark is-bold p-t-lg p-b-lg">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds">
                <h1 className="title">Produção de Imagens</h1>
                <h2 className="subtitle">
                  Também oferecemos produção fotográfica para catálogos, eventos
                  corporativos, pessoas, produtos, publicidade e institucional.
                </h2>
                <p>
                  Material produzido em campo (no próprio cliente) ou em
                  estúdio.{" "}
                  <Link to="/contato" className="is-destaque is-website">
                    Conheça
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="maisServicos" className="container m-b-lg">
        <div className="block">
          <h1 className="title is-3 has-text-centered p-lg">
            Ainda Oferecemos
          </h1>
          <div className="columns">
            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Registro de Domínios</p>
                  <p>
                    A <strong>SH2</strong> te auxilia em todo o processo para o
                    cadastro do seu domínio na web. Ajudamos também em toda a
                    configuração para você migrar seus registros pra cá.{" "}
                    <Link to="/contato">
                      Preciso de ajuda para registrar meu domínio.
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">Certificado Digital SSL</p>
                  <p>
                    Oferecemos venda e instalação de certificados digitais SSL.
                    Ganhe a confiança dos seus clientes ao criptografar suas
                    informações vitais, como números de cartão de crédito,
                    senhas e muito mais.{" "}
                    <Link to="/contato">Quero meu site com SSL.</Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card bm-card-equal-height">
                <div className="card-content">
                  <p className="title is-4">E-mail Marketing</p>
                  <p>
                    Fazemos a criação gráfica e montamos o documento para envio
                    de newsletters. Também oferecemos plataforma acessível para
                    o envio de e-mail marketing.{" "}
                    <Link to="/contato">
                      Quero enviar informativos e promoções para meus clientes.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SitePage
